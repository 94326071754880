<template>
  <div class="sectionContainer">
    <div class="centeredInnerSection profilePageGrid">
      <router-link to="/tabs/faq">
        <div class="profilePageGrid-Item">
          <img
            src="../assets/icons/SVG/externeIcons/faq.svg"
            alt="profile-button-icons"
          />
          FAQ
        </div>
      </router-link>
      <a href="mailto: support@frostberry.de">
        <div class="profilePageGrid-Item">
          <img
            src="../assets/icons/SVG/externeIcons/dialog.svg"
            alt="profile-button-icons"
          />
          Do you need help?
        </div>
      </a>
      <div class="profilePageGrid-Item" @click="openManageSubscription">
        <img
          src="../assets/icons/SVG/externeIcons/recurring-subscription.svg"
          alt="profile-button-icons"
        />
        Manage Subscription
      </div>
      <div class="profilePageGrid-Item" @click="openChangePassword">
        <img
          src="../assets/icons/SVG/externeIcons/password-reset.svg"
          alt="profile-button-icons"
        />
        Change Password
      </div>
      <div class="profilePageGrid-Item" @click="logout">
        <img
          src="../assets/icons/SVG/externeIcons/logout-line.svg"
          alt="profile-button-icons"
        />
        Log Out
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import Swal from "sweetalert2";
const ServerURL = process.env.VUE_APP_ENV_SERVER || "";
import axios from "axios";

export default {
  name: "Profile",
  setup() {
    function logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    }
    function formatDate(date) {
      let day = date.getDate();
      let month = date.getMonth();
      month = month + 1;
      if (String(day).length == 1) day = "0" + day;
      if (String(month).length == 1) month = "0" + month;

      let dateT = day + "." + month + "." + date.getFullYear();
      return dateT;
    }
    function openManageSubscription() {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          axios
            .get(ServerURL + "/getSubscription", {
              params: {
                idToken: idToken,
              },
            })
            .then((response) => response.data)
            .then(async (subscriptionData) => {
              console.log(subscriptionData);
              if (subscriptionData.cancel_at_period_end) {
                Swal.fire({
                  title: "Your Subscription",
                  html: `
                <p>Your subscription will end at the end of the period</p>
                <p>End of period: ${formatDate(
                  new Date(subscriptionData.current_period_end * 1000)
                )}</p>
                `,
                  confirmButtonText: "OK",
                });
              } else {
                Swal.fire({
                  title: "Your Subscription",
                  html: `
                <p>Status: ${subscriptionData.status.toUpperCase()}</p>
                <p>Next Payment: ${formatDate(
                  new Date(subscriptionData.current_period_end * 1000)
                )}</p>
                `,
                  showCancelButton: true,
                  cancelButtonText: "Close",
                  confirmButtonText: "Cancel Subscription",
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire({
                      title: "Are you sure you want to end your subscription?",
                      text: "Your subscription will be cancelled by the end of the period.",
                      showCancelButton: true,
                      cancelButtonText: "Close",
                      confirmButtonText: "Yes, cancel my subscription",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        axios
                          .get(ServerURL + "/cancelSubscription", {
                            params: {
                              idToken: idToken,
                            },
                          })
                          .then((response) => response.data)
                          .then(async (subscriptionCancelData) => {
                            console.log(subscriptionCancelData);
                          });
                      }
                    });
                  }
                });
              }
            });
        });
    }
    function openChangePassword() {
      const user = firebase.auth().currentUser;
      Swal.fire({
        title: "Current password",
        input: "password",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Next",
      }).then((currentPassword) => {
        /* Read more about isConfirmed, isDenied below */
        if (currentPassword.isConfirmed) {
          user
            .reauthenticateWithCredential(
              firebase.auth.EmailAuthProvider.credential(
                user.email,
                currentPassword.value
              )
            )
            .then(() => {
              Swal.fire({
                title: "New password",
                input: "password",
                inputAttributes: {
                  autocapitalize: "off",
                },
                showCancelButton: true,
                confirmButtonText: "Next",
              }).then((newPassword1) => {
                if (newPassword1.isConfirmed) {
                  Swal.fire({
                    title: "Repeat password",
                    input: "password",
                    inputAttributes: {
                      autocapitalize: "off",
                    },
                    showCancelButton: true,
                    confirmButtonText: "Next",
                  }).then((newPassword2) => {
                    if (newPassword2.isConfirmed) {
                      if (newPassword1.value == newPassword2.value) {
                        user
                          .updatePassword(newPassword1.value)
                          .then(() => {
                            Swal.fire({
                              title: "Password succesfully changed",
                              icon: "success",
                              confirmButtonText: "Okay",
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                            Swal.fire({
                              title: "Unexpected error...",
                              text: "Please contact support",
                              icon: "error",
                              confirmButtonText: "Okay",
                            });
                          });
                      } else {
                        Swal.fire({
                          title: "Error!",
                          text: "Password don't match",
                          icon: "error",
                          confirmButtonText: "Okay",
                        });
                      }
                    }
                  });
                }
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                title: "Error!",
                text: "Incorrect Password",
                icon: "error",
                confirmButtonText: "Okay",
              });
            });
        }
      });
    }
    return {
      openChangePassword,
      openManageSubscription,
      logout,
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  filter: invert(100%);
}
</style>
